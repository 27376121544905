import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week Starts Today!`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no 4:00 class at East today.`}</em></strong></p>
    <p><strong parentName="p">{`McGhee`}</strong></p>
    <p>{`30:00 AMRAP of:`}</p>
    <p>{`5 Deadlifts (275/185)`}</p>
    <p>{`13 Pushups`}</p>
    <p>{`9 Box Jumps (24/20)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday, October 28th, The Ville will be hosting a
fundraising wod for the family of LMPD Officer Jason Schweitzer who was
tragically killed by a drunk driver in Lexington, Ky.  You can donate
and purchase a shirt at the link below.  CrossFit 222 will show up as
the host but you can choose your shirt size and our location when
checking out.  You will also be able to donate on the day of the
competition.  Please sign up soon and show your support for one of our
LMPD officers and his family!  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Event brite
link: `}<a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1"
          }}>{`https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1`}</a></em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  There is still time to get your team
registered. Truegritcomp.wordpress.com.`}<strong parentName="p">{`**`}</strong>{`  We need help judging and
in other areas so if you can help out please let us know.  There will be
no classes or open gym at either location on this day.  ***`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2"
      }}>{`https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      